<template>
  <div id="app">
    <header v-bind:class="{ home: onHome }">
      <div id="site-header">
        <div id="site-banner">
          <router-link to="/">
            <img src="./assets/logo.png" alt="Lifespring Logo">
            <span id="site-title">Lifespring Cancer<br>Treatment Center</span>
          </router-link>
        </div>
        <nav id="site-navigation">

          <button id="menu-toggle" v-on:click="toggleMenu" v-bind:class="{opened: menuOpened}">
            <span class="navicon"></span>
          </button>

          <ul v-bind:class="{opened: menuOpened}">
            <li v-for="page in pages" v-bind:key="page.slug">
              <router-link v-bind:to="'/' + page.slug">{{ page.title }}</router-link>
              <ul v-if="page.children.length" class="sub-nav">
                <li v-for="childPage in page.children" v-bind:key="childPage.slug">
                  <router-link v-bind:to="'/' + page.slug +'/' + childPage.slug">
                    {{ childPage.title }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <div id="page-area" v-if="menuOpened" v-on:click="toggleMenu"></div>

      <div id="secondary-header">
        <div id="secondary-navigation"></div>
        <a href="tel:+12066861266" class="button">Appointments: 1 (206) 686-1266</a>
      </div>
    </header>
    <div id="site-content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.params.slug"></router-view>
      </transition>
    </div>
    <footer id="site-footer-primary">
      <div class="grid wide-wrapper">
        <div class="one-third">
          <ul class="bulletless">
            <li class="title"><router-link to="/about-us">About Us</router-link></li>
            <li>
              <ul class="bulletless">
                <li>
                  <router-link to="/about-us/our-practice">
                    Our Practice
                  </router-link>
                </li>
                <li>
                  <router-link to="/about-us/our-team">
                    Our Team
                  </router-link>
                </li>
                <li>
                  <router-link to="/about-us/service">
                    Service
                  </router-link>
                </li>
                <li>
                  <router-link to="/about-us/our-facility">
                    Our Facility
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="bulletless">
            <li class="title"><router-link to="/our-treatment">Our Treatment</router-link></li>
            <li>
              <ul class="bulletless">
                <li>
                  <router-link to="/our-treatment/low-dose-metronomic-chemotherapy">
                    Low Dose/Metronomic Chemotherapy
                  </router-link>
                </li>
                <li>
                  <router-link to="/our-treatment/sequential-therapy">
                    Sequential Therapy
                  </router-link>
                </li>
                <li>
                  <router-link to="/our-treatment/personalized-medicine">
                    Personalized Medicine
                  </router-link>
                </li>
                <li>
                  <router-link to="/our-treatment/immunotherapy">
                    Immunotherapy
                  </router-link>
                </li>
                <li>
                  <router-link to="/our-treatment/testimonials">
                    Testimonials
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="one-third">
          <ul class="bulletless">
            <li class="title"><router-link to="/why-lifespring">Why Lifespring</router-link></li>
          </ul>

          <ul class="bulletless">
            <li class="title"><router-link to="/new-patients">New Patients</router-link></li>
            <li>
              <ul class="bulletless">
                <li>
                  <router-link to="/new-patients/insurances-accepted">
                    Insurances Accepted
                  </router-link>
                </li>
                <li>
                  <router-link to="/new-patients/schedule-an-appointment">
                    Schedule an Appointment
                  </router-link>
                </li>
                <li>
                  <router-link to="/new-patients/patient-intake-forms">
                    Patient Intake Forms
                  </router-link>
                </li>
                <li>
                  <router-link to="/new-patients/resources">
                    Resources
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="bulletless">
            <li class="title"><router-link to="/publications">Publications</router-link></li>
            <li>
              <ul class="bulletless">
                <li>
                  <router-link to="/publications/research">
                    Research
                  </router-link>
                </li>
                <!--<li><router-link to="/news-articles">News Articles</router-link></li>-->
              </ul>
            </li>
          </ul>
        </div>
        <div class="one-third">
          <ul class="bulletless">
            <li class="title"><router-link to="/contact">Contact</router-link></li>
            <li>
              <ul class="bulletless">
                <li>
                  <router-link to="/contact/address-and-hours">
                    Address &amp; Hours
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact/transportation">
                    Transportation
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact/careers">
                    Careers
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>

          <address>
            <span class="title">Lifespring Cancer Treatment Center</span><br />
            510 Rainier Ave S<br />
            Seattle, WA 98144<br />
            Ph: <a href="tel:+12066861266">1 (206) 686-1266</a><br />
            Fx: 1 (206) 686-1268<br />
            Fx: 1 (206) 328-5895
          </address>

          <a href="https://www.facebook.com/pg/Lifespring-Cancer-Treatment-Center-149187401766275/" target="_blank"><img src="./assets/facebook-icon.png" alt="Facebook" /></a>
        </div>
      </div>
    </footer>
    <footer id="site-footer-secondary">
      <span>Copyright &copy; 2011-{{ currentYear }} Lifespring Cancer Treatment Center</span>
    </footer>
    <notice heading="We Use Cookies" ref="cookieNotice" v-if="!acceptedCookies">
      <div class="grid gutter">
          <div class="three-fourths">
            <p>{{ content.cookieNotice }}</p>
          </div>
          <div class="one-fourth align-right">
            <div class="button-group">
              <button v-on:click="acceptCookies()" class="primary">Accept &amp; Close</button>
            </div>
          </div>
        </div>
    </notice>
  </div>
</template>

<script>
import axios from 'axios';
import Notice from '@/components/Notice.vue';

const apiHost = process.env.VUE_APP_CONTENT_HOST;

export default {
  name: 'Lifespring',
  components: {
    Notice,
  },
  metaInfo: {
    title: 'Lifespring Cancer Treatment Center | Seattle, WA',
    titleTemplate: '%s | Lifespring Cancer Treatment Center',
  },
  data() {
    return {
      content: {
        cookieNotice: 'We use cookies and other tracking technologies to analyze our website traffic, to understand where our visitors are coming from, and to improve your browsing experience on our website. We do not sell any of the information we collect from you. By browsing this website, you consent to our use of cookies and other tracking technologies.',
      },
      pages: [],
      onHome: true,
      menuOpened: false,
      currentYear: '',
      acceptedCookies: false,
    };
  },
  methods: {
    // Sticky header
    stickyHeader() {
      const siteHeader = document.getElementById('site-header');
      const siteNavigation = document.getElementById('site-navigation');
      const secondaryHeader = document.getElementById('secondary-header');
      const secondaryNavigation = document.getElementById('secondary-navigation');
      const siteContent = document.getElementById('site-content');

      const navMenu = siteNavigation.innerHTML;

      if (window.innerWidth > 700) {
        if (window.scrollY > siteHeader.offsetHeight) {
          secondaryHeader.style.position = 'fixed';
          siteContent.style.marginTop = `${secondaryHeader.offsetHeight}px`;
          secondaryNavigation.innerHTML = navMenu;

          secondaryHeader.style.backgroundColor = '#113541';
        } else {
          secondaryHeader.style.position = 'relative';
          siteContent.style.marginTop = 0;
          secondaryNavigation.innerHTML = null;

          if (this.onHome) {
            secondaryHeader.style.backgroundColor = 'transparent';
          } else {
            secondaryHeader.style.backgroundColor = '#113541';
          }
        }
      } else {
        document.getElementById('site-content').style.marginTop = `${siteHeader.offsetHeight}px`;
      }
    },
    toggleMenu() {
      if (this.menuOpened) {
        this.menuOpened = false;
      } else {
        this.menuOpened = true;
      }
    },
    acceptCookies() {
      // Promise to allow transition animation
      this.$refs.cookieNotice.dismiss()
        .then(() => {
          this.acceptedCookies = true;
        });
      this.$util.setCookie('accepted-cookies', true, 30);
      this.$util.enableTracking(this.$ga);
    },
  },
  mounted() {
    this.acceptedCookies = this.$util.getCookie('accepted-cookies');

    // Get pages for navigation
    const url = `${apiHost}/pages`;

    axios
      .get(url)
      .then((response) => {
        const pageDump = response.data.data;

        // Build list of top-level pages
        pageDump.forEach((page) => {
          if (page.parentId == null) {
            const topLevelPage = {
              id: page.id,
              title: page.title,
              slug: page.slug,
              children: [],
            };

            this.pages.push(topLevelPage);
          }
        });

        // Add child pages to each top-level page based on ID match
        this.pages.forEach((topLevelPage) => {
          pageDump.forEach((page) => {
            if (page.parentId === topLevelPage.id) {
              const childPage = {
                id: page.id,
                title: page.title,
                slug: page.slug,
                sort: page.sort,
              };

              topLevelPage.children.push(childPage);
            }
          });
        });
      });

    // Get current year for copyright
    this.currentYear = new Date().getFullYear();
  },
  watch: {
    $route() {
      if (this.$route.path === '/') {
        this.onHome = true;
      } else {
        this.onHome = false;
      }

      this.stickyHeader();
    },
  },
  created() {
    window.addEventListener('scroll', this.stickyHeader);
  },
  destroyed() {
    window.removeEventListener('scroll', this.stickyHeader);
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,500,500i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i');

$breakpoint-alpha: 960px;
$breakpoint-alpha-gutter: 50px;
$breakpoint-bravo: 700px;
$breakpoint-bravo-gutter: 50px;
$breakpoint-charlie: 480px;
$breakpoint-charlie-gutter: 25px;

$primary-color: #113440;
$secondary-color: #ff921c;
$tertiary-color: #00a7c0;

body {
  margin: 0;
  overflow-x: hidden;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 40px;
  margin: 0 0 28px 0;
}

h2 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 28px;
  font-weight: normal;
  line-height: 32px;
  margin: 0 0 28px 0;
}

h3 {
  margin: 0 0 28px 0;
}

a {
  color: #00a7c0;
  text-decoration: none;

  &:hover {
    color: #ff921c;
  }
}

p {
  line-height: 1.56em;
  margin: 0 0 28px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  margin: 0 0 28px 0;
  padding-left: 20px;
  line-height: 28px;

  &.bulletless,
  nav & {
    list-style-type: none;
    padding: 0;
  }
}

dl {
  line-height: 28px;
  margin: 0 0 28px 0;
}

dt {
  clear: left;
  float: left;
  font-weight: bold;
  margin-right: 10px;

  &:after {
    content: ":";
  }
}

img {
  margin-bottom: 24px;
  max-width: 100%;

  &[style="float: left;"] {
    margin-right: 20px;
  }

  &[style="float: right;"] {
    margin-left: 20px;
  }

  @media (max-width: $breakpoint-charlie) {
    float: none !important;
  }
}

hr {
  background-color: #113541;
  border: 0;
  height: 1px;
  margin: 0 0 28px 0;
}

address {
  font-style: normal;
  margin-bottom: 28px;
}

form {
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  margin: auto;
  padding: 50px;
  max-width: 500px;

  :last-child {
    margin-bottom: 0;
  }
}

label {
  display: block;
}

input {
  border: 2px solid transparent;
  box-sizing: border-box;
  background-color: #eee;
  border-radius: 3px;
  color: #113541;
  display: block;
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px 12px;
  width: 100%;

  &:focus {
    border: 2px solid #c9cdcb;
  }
}

button,
.button {
  display: inline-block;
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 10px 28px 10px;
  padding: 10px 12px;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.2s, border 0.2s, color 0.2s;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.centered {
    left: 50%;
    position: relative;
    /* calculate 50% of element width and move left across X-axis */
    transform: translateX(-50%);
  }

  &:hover {
    cursor: pointer;
  }

  &,
  &.primary {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $primary-color;

    &:hover {
      background-color: $tertiary-color;
      border-color: $tertiary-color;
      color: $primary-color;
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
      border-color: $tertiary-color;
      color: $tertiary-color;
    }
  }

  &.tertiary {
    background-color: transparent;
    border: 1px solid transparent;
    color: $secondary-color;

    &:hover {
      color: $tertiary-color;
    }
  }

  .secondary-color & {
    &.primary {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: #fff;

      &:hover {
        background-color: $tertiary-color;
        border-color: $tertiary-color;
        color: $primary-color;
      }
    }

    &.secondary {
      background-color: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;

      &:hover {
        border-color: $tertiary-color;
        color: $tertiary-color;
      }
    }

    &.tertiary {
      background-color: transparent;
      border: 1px solid transparent;
      color: $primary-color;

      &:hover {
        color: $tertiary-color;
      }
    }
  }
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  button,
  .button {
    margin-bottom: 0;
  }
}

.help-text {
  font-size: 14px;
}

.confirm {
  display: none;
}

table {
  border-collapse: collapse;
  font-size: 14px;
  margin-bottom: 30px;
  max-width: 100%;

  caption {
    text-align: left;
  }
}

tr {
  border-top: 1px solid #113440;
  border-bottom: 1px solid #113440;
}

th,
td {
  display: table-cell;
  padding: 8px;
  text-align: left;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: $breakpoint-bravo) {
    padding: 8px 0;
  }
}

th {
  @media (max-width: $breakpoint-bravo) {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
  }
}

td {
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-bravo) {
    display: block;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }
}

tbody td {
  &:before {
    content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
    font-weight: bold;
    display: block;

    @media (min-width: $breakpoint-bravo) {
      display: none;
    }
  }
}

tfoot,
.footnotes,
figcaption {
  font-size: 12px;

  p,
  ul,
  ol {
    line-height: 20px;
  }
}

figure {
  margin: 0 0 28px 0;

  img {
    margin: 0;
  }
}

caption,
figcaption {
  margin-bottom: 16px;

  @media (max-width: $breakpoint-bravo + 100) {
    margin: 0 16px 16px 16px;
  }
}

.footnotes {
  border-top: 1px solid #113541;
  padding-top: 28px;

  h2 {
    font-size: 12px;
    font-family: Karla, Helvetica, Arial, sans-serif;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 22px;
  }
}

#app {
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #113541;
}

#site-header {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 2;

  .home & {
    background-color: transparent;
  }

  @media (max-width: $breakpoint-bravo) {
    align-items: stretch;
    height: auto;
    position: fixed;
    top: 0;
  }
}

#site-banner {
  align-items: center;
  display: flex;
  flex-grow: 0;
  line-height: 1;
  padding: 5px;

  img {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-alpha) {
    img {
      max-width: 180px;
    }
  }

  @media (max-width: $breakpoint-bravo) {
    background-color: #113541;
    flex-grow: 1;

    img {
      display: none;
    }
  }
}

#site-title {
  color: #fff;
  display: none;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  margin-left: 10px;

  @media (max-width: $breakpoint-bravo) {
    display: block;
  }
}

#site-navigation {
  display: inline-block;
  flex-grow: 1;
  position: relative;
  z-index: 1;

  & > ul {
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: right;

    & > li {
      display: inline-block;
      font-family: 'Fira Sans', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 5px 0;
      position: relative;

      & > a {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        color: #2c3e50;
        display: block;
        padding: 4px 10px;
        text-decoration: none;
      }

      &:hover > a {
        border-bottom: 3px solid #113541;
      }

      &:last-child .sub-nav {
        right: 0;
      }
    }
  }

  @media (max-width: $breakpoint-alpha) {
    & > ul > li {
      font-size: 14px;
    }
  }

  @media (max-width: $breakpoint-bravo) {
    flex-grow: 0;
    order: -1;

    & > ul {
      bottom: 0;
      background-color: #113541;
      left: -85%;
      padding-top: 58px;
      position: fixed;
      top: 0;
      transition: left .1s ease-out;
      width: 85%;

      & > li {
        display: block;
        text-align: left;

        & > a {
          color: #fff;
          padding: 20px 10px;
        }
      }
    }

    & > ul.opened {
      left: 0;
    }
  }
}

#page-area {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

#menu-toggle {
  display: none;
  margin: 0;
  padding: 28px 20px;
  position: relative;
  top: 0;
  z-index: 1;

  &:hover {
    cursor: pointer;

    .navicon,
    .navicon:before,
    .navicon:after {
      background-color: #fff;
    }
  }

  @media (max-width: $breakpoint-bravo) {
    display: block;
  }
}

#menu-toggle.opened .navicon {
  background: transparent;

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}

#menu-toggle.opened:not(.steps) .navicon:before,
#menu-toggle.opened:not(.steps) .navicon:after {
  top: 0;
}

.navicon {
  background: #113541;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;

  &:before,
  &:after {
    background: #113541;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  &:before {
    top: 5px;
  }

  &:after {
    top: -5px;
  }
}

#secondary-header {
  align-items: center;
  background-color: #113541;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  height: 50px;
  top: 0;
  width: 100%;
  z-index: 1;

  div {
    flex-grow: 1;
  }

  .button {
    background-color: #ff921d;
    border: 0;
    color: #113541;
    flex-grow: 0;
    font-family: Karla, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 18px 20px;
    text-decoration: none;
    text-transform: uppercase;
  }

  @media (max-width: $breakpoint-alpha) {
    .button {
      display: none;
    }
  }

  @media (max-width: $breakpoint-bravo) {
    display: none;
  }
}

#secondary-navigation {
  text-transform: uppercase;

  & > ul {
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      position: relative;

      & > a {
        color: #fff;
        display: inline-block;
        line-height: 1;
        padding: 18px 15px;
      }
    }
  }
}

.sub-nav {
  background-color: #ff921d;
  height: auto;
  overflow: hidden;
  margin: 0;
  max-height: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  width: 200px;
  z-index: 2;
  transition: max-height .3s ease-out;

  li:hover & {
    max-height: 500px !important;
    position: absolute;
  }

  & > li {
    display: block;
    font-family: Karla, Helvetica, Arial, sans-serif;
    font-size: 16px;
    text-transform: none;
    font-weight: normal;

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    & > a {
      border-top: 0;
      border-right: 3px solid transparent;
      border-bottom: 0;
      border-left: 3px solid transparent;
      color: #2c3e50;
      display: block;
      line-height: 22px;
      padding: 4px 10px;
      text-decoration: none;
    }

    &:hover > a {
      border-left: 3px solid #113541;
    }
  }

  .router-link-exact-active {
    border-left: 3px solid #113541 !important;
  }

  @media (max-width: $breakpoint-bravo) {
    background-color: #224652;
    position: static !important;
    width: 100%;

    & > li {
      & > a {
        color: #fff;
        padding: 10px;
      }

      &:hover > a {
        border-left: 3px solid #fff;
      }
    }

    .router-link-exact-active {
      border-left: 3px solid #fff !important;
    }
  }
}

#site-content {
  background-color: #fff;//#f5f1e9;
}

#site-footer-primary {
  background-color: #113541;
  color: #fff;
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 50px 0;

  * {
    line-height: 26px;
  }

  a {
    color: #fff;
  }

  img {
    margin: 0 10px 0 0;
  }
}

#site-footer-secondary {
  background-color: #ff921d;
  color: #113541;
  font-family: Karla, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active,
.v-enter-active,
.v-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to,
.v-enter,
.v-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to,
.v-leave,
.v-enter-to {
  opacity: 1;
}

.wide-wrapper {
  margin: auto;
  max-width: $breakpoint-alpha;
  padding: 0 30px;
}

.page-wrapper {
  margin: auto;
  max-width: $breakpoint-bravo;
  padding: $breakpoint-bravo-gutter;

  @media (max-width: $breakpoint-charlie) {
    padding: 50px $breakpoint-charlie-gutter;
  }
}

.grid {
  align-items: stretch;
  display: flex;
  flex-flow: row;

  &.vertically-centered {
    align-items: center;
  }

  @media (max-width: $breakpoint-bravo) {
    flex-wrap: wrap;
  }

  &.gutter > * {
    padding: 0 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: $breakpoint-bravo) {
      padding: 0 0 1.56em 0;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.one-third {
  box-sizing: border-box;
  width: 33.33%;

  @media (max-width: $breakpoint-bravo) {
    display: block;
    width: auto;
  }
}

.two-thirds {
  box-sizing: border-box;
  width: 66.66%;

  @media (max-width: $breakpoint-bravo) {
    display: block;
    width: 100%;
  }
}

.one-fourth {
  box-sizing: border-box;
  width: 25%;

  @media (max-width: $breakpoint-bravo) {
    display: block;
    width: 100%;
  }
}

.three-fourths {
  box-sizing: border-box;
  width: 75%;

  @media (max-width: $breakpoint-bravo) {
    display: block;
    width: 100%;
  }
}

.one-half {
  box-sizing: border-box;
  width: 50%;

  @media (max-width: $breakpoint-bravo) {
    display: block;
    width: 100%;
  }
}

.margined {
  margin: 50px 0;
  padding: 0 50px;

  &:first-of-type {
    border-right: 1px solid #113541;
  }

  @media (max-width: $breakpoint-bravo) {
    margin: 0 50px;
    padding: 50px 0;

    &:first-of-type {
      border-bottom: 1px solid #113541;
      border-right: none;
    }
  }

  @media (max-width: $breakpoint-charlie) {
    margin: 0 30px;
    padding: 50px 0;
  }
}

.padded {
  padding: 50px;

  @media (max-width: $breakpoint-bravo) {
    padding: $breakpoint-bravo-gutter;
  }

  @media (max-width: $breakpoint-charlie) {
    padding: 50px $breakpoint-charlie-gutter;
  }
}

.align-right {
  text-align: right;

  @media (max-width: $breakpoint-charlie) {
    text-align: left;
  }
}

.centered {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.primary-color {
  background-color: #113541;
  color: #fff;
}

.secondary-color {
  background-color: #ff921c;
  color: #113541;
}

.tertiary-coor {
  background-color: #f5f1e9;
  color: #113541;
}

.quaternary-color {
  background-color: #fff;
  color: #113541;
}

.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.spill {
  height: auto;
  margin-right: 0;
  margin-left: 0;
  max-width: calc(100% + #{$breakpoint-bravo-gutter * 2});
  width: calc(100% + #{$breakpoint-bravo-gutter * 2});
  transform: translateX(-$breakpoint-bravo-gutter);

  @media (max-width: $breakpoint-charlie) {
    max-width: calc(100% + #{$breakpoint-charlie-gutter * 2});
    width: calc(100% + #{$breakpoint-charlie-gutter * 2});
    transform: translateX(-$breakpoint-charlie-gutter);
  }
}

.map {
  height: 300px;
}

.section-title {
  color: #ff921c;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  line-height: 40px;
  margin: 0;
  text-transform: uppercase;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle {
  display: block;
  line-height: 1.5;
  margin: 0 0 28px 0;
  text-transform: uppercase;

  h1 + &,
  h2 + & {
    margin-top: -20px;
  }
}

.metadata {
  border-top: 1px solid #113541;
  font-size: 14px;
  padding-top: 28px;
}
</style>
