import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 50 },
      };
    } if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/about-us', redirect: '/about-us/our-practice' },
    { path: '/our-treatment', redirect: '/our-treatment/low-dose-metronomic-chemotherapy' },
    { path: '/new-patients', redirect: '/new-patients/schedule-an-appointment' },
    { path: '/publications', redirect: '/publications/research' },
    { path: '/contact', redirect: '/contact/address-and-hours' },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/publications/research',
      name: 'research',
      component: () => import('./views/Research.vue'),
    },
    {
      path: '/publications/research/:slug',
      name: 'researchArticle',
      component: () => import('./views/ResearchArticle.vue'),
    },
    {
      path: '/contact/careers',
      name: 'careers',
      component: () => import('./views/Careers.vue'),
    },
    {
      path: '/contact/careers/:slug',
      name: 'career',
      component: () => import('./views/Career.vue'),
    },
    {
      path: '/:placeholder/:slug',
      name: 'childPage',
      component: () => import('./views/Page.vue'),
    },
    {
      path: '/:slug',
      name: 'page',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Page.vue'),
    },
  ],
});
