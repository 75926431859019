const util = {
  // Run upon every page load
  bootstrap() {
    /* Clear noindex */
    const noIndex = document.getElementById('noindex');

    if (noIndex) {
      noIndex.remove();
    }
  },
  // Add base URL for images if missing
  prependAssetSrc() {
    const ASSET_BASE_URL = 'https://content.lifespringcancercenter.com';
    const pageContent = document.getElementById('page-content');

    // Ensure page content is not missing (possible if 404)
    if (pageContent) {
      const images = document.getElementById('page-content').querySelectorAll('img');

      images.forEach((image) => {
        const src = image.getAttribute('src');
        if (src.substring(0, 4) !== 'http') {
          image.setAttribute('src', ASSET_BASE_URL + src);
        }
      });
    }
  },
  getCookie(cookieName) {
    // No cookies at all
    if (!document.cookie) {
      return null;
    }
    const cookieDump = document.cookie.split(';');
    const cookies    = {};

    cookieDump.forEach((cookieString) => {
      const cookie = cookieString.split('=');
      cookies[cookie[0].trim()] = cookie[1].trim();
    });

    if (cookieName) {
      if (cookies[cookieName]) {
        return cookies[cookieName];
      }
      return null;
    }

    // No specific cookie specified, so return all
    return cookies;
  },
  setCookie(cookieName, cookieValue, daysToLive) {
    // Cookie name must be specified
    if (!cookieName) {
      return;
    }

    // Calculate expiration date
    let expires = '';
    if (daysToLive) {
      const date = new Date();
      date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${cookieName}=${cookieValue}${expires};secure;samesite=lax`;
  },
  deleteCookie(cookieName) {
    // Cookie name must be specified
    if (!cookieName) {
      return;
    }

    document.cookie = `${cookieName}=;expires=0`;
  },
  enableTracking(ga) {
    // Need to allow this.$ga to be passed from caller (cannot access scope from here)
    ga.enable();
  },
};

function install(Vue) {
  Vue.prototype.$util = util;
}

export { install as utilPlugin, util as utilObject };
