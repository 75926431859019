<template>
  <div class="banner secondary-color">
    <div class="wide-wrapper">
      <h3>{{ heading }}</h3>
      <slot>
        This is a notice.
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  metaInfo() {
    return {
      title: this.title,
    };
  },
  props: {
    heading: {
      type: String,
      default: 'Notice',
    },
  },
  methods: {
    dismiss(id) {
      return new Promise((resolve) => {
        let notice;

        if (id) {
          notice = document.getElementById(id);
        } else {
          notice = this.$el;
        }

        if (notice) {
          notice.style.bottom = '-100%';
        }

        setTimeout(resolve, 300);
      });
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.banner {
  bottom: 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.8em;
  padding: 40px 0;
  position: fixed;
  transition: bottom 0.3s ease-out;
  width: 100%;
}
</style>
