import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { utilPlugin, utilObject } from '@/util';
import router from './router';
import App from './App.vue';

library.add(faCircleNotch);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

const isProd = process.env.NODE_ENV === 'production';

Vue.use(utilPlugin);
Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: 'UA-35588799-1',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
  disabled: utilObject.getCookie('accepted-cookies') == null ? true : !utilObject.getCookie('accepted-cookies'),
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
