<template>
  <div>
    <section id="splash">
      <h1>You are a <em>unique</em> human being.</h1>
    </section>
    <section class="grid vertically-centered">
      <div class="one-third padded centered">
        <span>We are an oncology clinic in Seattle, Washington.</span>
        <img src="../assets/washington-state-map.png" alt="Washington State" />
      </div>
      <div class="two-thirds padded">
        <h1>Who we are</h1>

        <p>At Lifespring Cancer Treatment Center, we understand that you are not just a patient, you are a unique human being. We provide our patients with state of the art and innovative cancer treatment, including low dose chemotherapy tailored just for you by the pioneer in the field. We also utilize the latest immune and targeted therapies. You will also have the option of meeting with our naturopath to further support your treatments. Our treatment strategy is focused on being more tolerable so you can continue to enjoy a good quality of life. We pride ourselves in getting to know you outside of your cancer diagnosis and providing you with efficient care.</p>

        <router-link to="our-treatment" class="button">
          Learn More About Your Treatment Options
        </router-link>
      </div>
    </section>
    <section class="primary-color">
      <div class="page-wrapper">
        <h1 class="centered">Are you ready?</h1>

        <ul>
          <li>
            Are you seeking a personalized state of the art cancer treatment that minimizes side effects and maximizes treatment outcomes?
          </li>
          <li>
            Do you want to receive treatment at a facility where the staff knows you and your family on a first name basis?
          </li>
          <li>
            Do you wish a physician was on site and able to see you before each treatment to address issues before they got out of hand?
          </li>
          <li>
            How about have a physician available 24/7 by phone if something happens outside of business hours?
          </li>
          <li>
            Do you dream of receiving personalized and efficient care without institutional/bureaucratic barriers?
          </li>
        </ul>

        <p class="centered">If you answered yes to any of these questions, we can help you.</p>

        <router-link to="/new-patients/schedule-an-appointment" class="button centered">
          Schedule an Appointment to Learn More
        </router-link>
      </div>
    </section>
    <section class="tertiary-color grid">
      <div class="one-half margined">
        <div class="one-third">
        </div>
        <div class="two-thirds">
          <h1>Don't just take our word for it.</h1>

          <p>
            See what people are saying about their experience on Healthgrades, Vitals, Yelp, and Facebook.
          </p>
        </div>
      </div>
      <div class="one-half margined">
        <a href="https://www.healthgrades.com/physician/dr-ben-chue-x56qg" target="_blank"><img src="../assets/healthgrades-logo.png" alt="Healthgrades" /></a>
        <a href="https://www.vitals.com/doctors/Dr_Ben_Chue/reviews?page=" target="_blank"><img src="../assets/vitals-logo.png" alt="Vitals" /></a>
        <a href="https://www.yelp.com/biz/lifespring-cancer-treatment-center-seattle" target="_blank"><img src="../assets/yelp-logo.png" alt="Yelp" /></a>
        <a href="https://www.facebook.com/pg/Lifespring-Cancer-Treatment-Center-149187401766275/reviews/?referrer=page_recommendations_see_all&ref=page_internal" target="_blank"><img src="../assets/facebook-logo.png" alt="Facebook" /></a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'home',
  metaInfo: {
    title: 'Lifespring Cancer Treatment Center | Seattle, WA',
    titleTemplate: null,
  },
  data() {
    return {
      info: 'Welcome',
    };
  },
  beforeCreate() {
    this.$util.bootstrap();
  },
  mounted() {
    const siteHeader = document.getElementById('site-header');
    const secondaryHeader = document.getElementById('secondary-header');
    let splashHeight;
    let splashOffset;

    if (window.innerWidth > 700) {
      splashHeight = `${window.innerHeight}px`;
      splashOffset = `-${siteHeader.offsetHeight + secondaryHeader.offsetHeight}px`;
    } else {
      splashHeight = `${window.innerHeight - siteHeader.offsetHeight}px`;
      splashOffset = `${siteHeader.offsetHeight}px`;
    }

    document.getElementById('splash').style.height = splashHeight;
    document.getElementById('splash').style.marginTop = splashOffset;
  },
};
</script>

<style scoped lang="scss">
#splash {
  align-items: center;
  background-image: url(../assets/splash-bg.jpg);
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 50px;
  vertical-align: middle;

  h1 {
    font-size: 60px;
    max-width: 500px;
  }

  @media (max-width: 700px) {
    padding: 30px;

    h1 {
      font-size: 45px;
      line-height: 0.8;
    }
  }
}

h1 em {
  border-bottom: 6px solid #4dcbde;
  display: inline-block;
  font-style: normal;
  line-height: 0.7;
  margin-right: -5px;
  padding-right: 5px;
}
</style>
